.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  display: flex;
  transform: rotateX(180deg);
  padding: 20px 0;
  height: 95vh;
}

.line {
  transition: height 200ms;
}

input {
  width: 50px;
}

button,
input {
  padding: 4px;
}

.buttons > :not(:last-child) {
  margin-right: 5px;
}
