* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

button,
input {
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
}
body {
  font-family: monospace;
  font-size: 16px;
}
